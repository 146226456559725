import React from 'react';
import styled from 'styled-components';
import BaseText from './BaseText';

const settings = {
  standard: {
    fontSize: '12px',
    lineHeight: '18px',
  },
  small: {
    fontSize: '10px',
    lineHeight: '14px',
  },
};

const TextWrapper = styled.p`
  opacity: 0.6;
`;

export const Disclaimer = ({
  isDarkMode,
  isLowContrast,
  isSmall,
  textCase,
  fontSize,
  children,
}) => (
  <BaseText
    isDarkMode={isDarkMode}
    isLowContrast={isLowContrast}
    isSmall={isSmall}
    textCase={textCase}
    fontSize={fontSize}
    settings={settings}
  >
    <TextWrapper>{children}</TextWrapper>
  </BaseText>
);
