import React from 'react';
import styled from 'styled-components';

const AnchorContainer = styled('a')`
  text-decoration: ${(props) => props.textDecoration || 'none'};
  cursor: ${(props) => props.cursor || 'pointer'};
  user-select: none;
`;

const Anchor = ({ href, children, openInNewTab, textDecoration }, ref) => {
  const isExternalLink = href.slice(0, 4) === 'http';

  const aProps = isExternalLink || openInNewTab ? { target: '_blank' } : {};

  if (isExternalLink) {
    aProps.rel = 'noopener noreferrer';
  }

  return (
    <AnchorContainer
      {...aProps}
      href={href}
      ref={ref}
      textDecoration={textDecoration}
    >
      {children}
    </AnchorContainer>
  );
};

export default React.forwardRef(Anchor);
