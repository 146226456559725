import { logAmplitudeEvent } from '@utils/amplitude';

const viewPage = {
  send: (properties) => {
    const event = 'View Enterprise Page';
    logAmplitudeEvent(event, properties);
  },
};
const viewContactPage = {
  send: (properties) => {
    const event = 'View Enterprise Contact Page';
    logAmplitudeEvent(event, properties);
  },
};
const submitContact = {
  send: (properties) => {
    const event = 'Submit Enterprise Contact Request';
    logAmplitudeEvent(event, properties);
  },
};
const clickGetInTouch = {
  send: (properties) => {
    const event = 'Click Get In Touch';
    logAmplitudeEvent(event, properties);
  },
};

const trackingEvents = {
  viewPage,
  viewContactPage,
  submitContact,
  clickGetInTouch,
};

export default trackingEvents;
