import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  path {
    stroke: ${(props) => (props.isDarkMode ? props.theme.secondary.white : props.theme.primary.charcoal)};
  }
  cursor: pointer;
`;

function Hamburger({ isDarkMode, width, id }) {
  const ratio = 17 / 24;
  const height = width * ratio;
  return (
    <Wrapper isDarkMode={isDarkMode} id={id}>
      <svg height={height} width={width} viewBox="0 0 24 17" fill="none">
        <path d="M1 8.5H23" strokeWidth="2" strokeLinecap="round" />
        <path d="M1 15.5H23" strokeWidth="2" strokeLinecap="round" />
        <path d="M1 1.5H23" strokeWidth="2" strokeLinecap="round" />
      </svg>
    </Wrapper>
  );
}

export default Hamburger;
