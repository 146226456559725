import React from "react";
import BaseText from "./BaseText";

const settings = {
  standard: {
    fontSize: "14px",
    lineHeight: "12px",
    textCase: "uppercase",
  },
  small: {
    fontSize: "14px",
    lineHeight: "20px",
    textCase: "uppercase",
  },
};

export const CTA = ({ isDarkMode, isSmall, children }) => (
  <BaseText isDarkMode={isDarkMode} isSmall={isSmall} settings={settings}>
    {children}
  </BaseText>
);
