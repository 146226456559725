import React from 'react';
import { withTheme } from 'styled-components';
import { Column } from '@lib/nvstr-common-ui.es';
import { Bold } from '@components/Text';

const Banner = ({ children, theme }) => {
  return (
    <Column hAlign="center" vAlign="center" height="32px" background={theme.secondary.yellow}>
      <Bold>{children}</Bold>
    </Column>
  );
};

export default withTheme(Banner);
