import React from 'react';
import { Body4, TextDefinition } from './';

export const TextDefinitionParagraph = ({ phrase, definition, bullets, noMargin }) => {
  return (
    <div>
      <TextDefinition phrase={phrase} definition={definition} />
      {bullets && (
        <ul>
          {bullets.map((text) => (
            <li key={text} className={noMargin ? 'no-margin' : ''}>
              <Body4>{text}</Body4>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
