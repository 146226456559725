import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  img {
    opacity: ${(props) => (isNaN(props.opacity) ? 1 : props.opacity)};
  }
`;

const SIPC = ({ width, opacity }) => {
  const aspectRatio = 436 / 180;
  const height = width / aspectRatio;
  return (
    <Wrapper opacity={opacity}>
      <img src="/images/sipc.png" width={width} height={height} alt="SIPC logo" />
    </Wrapper>
  );
};

export default SIPC;
