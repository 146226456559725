import React from 'react';
import styled, { withTheme } from 'styled-components';
import BaseButton from './BaseButton';
import { enums } from '@lib/nvstr-utils.es';
const { button_types } = enums;

const Wrapper = styled.span`
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
`;

const Button4 = ({ theme, children }) => (
  <BaseButton
    background={theme.primary.charcoal}
    hoverBackground={theme.secondary.white}
    borderHoverColor={theme.primary.charcoal}
    color={theme.secondary.white}
    hoverColor={theme.primary.charcoal}
    width="120px"
    height="50px"
    buttonType={button_types.button}
  >
    <Wrapper>{children}</Wrapper>
  </BaseButton>
);

export default withTheme(Button4);
