import React from 'react';
import styled, { withTheme } from 'styled-components';
import BaseButton from './BaseButton';
import { enums } from '@lib/nvstr-utils.es';
const { button_types } = enums;

const Wrapper = styled.span`
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
`;

// ${({ isDarkMode, theme }) => (isDarkMode ? theme.darkTheme.text : theme.lightTheme.text)}
const Button3 = ({ isDarkMode, theme, children, width, onClick }) => (
  <BaseButton
    background={'transparent'}
    hoverBackground={isDarkMode ? theme.darkTheme.text : theme.lightTheme.text}
    borderHoverColor={isDarkMode ? theme.darkTheme.text : theme.lightTheme.text}
    color={isDarkMode ? theme.darkTheme.text : theme.lightTheme.text}
    hoverColor={isDarkMode ? theme.darkTheme.component : theme.lightTheme.component}
    borderColor={isDarkMode ? theme.darkTheme.text : theme.lightTheme.text}
    width={width}
    onClick={onClick}
    buttonType={button_types.button}
  >
    <Wrapper>{children}</Wrapper>
  </BaseButton>
);

export default withTheme(Button3);
