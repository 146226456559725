import React from 'react';
import BaseText from './BaseText';

const settings = {
  standard: {
    fontSize: '64px',
    lineHeight: '68px',
    textCase: 'uppercase',
  },
  small: {
    fontSize: '36px',
    lineHeight: '36px',
    textCase: 'uppercase',
  },
};

export const H3 = (props) => (
  <BaseText settings={settings} {...props}>
    <h3>{props.children}</h3>
  </BaseText>
);
