import React from 'react';
import BaseText from './BaseText';

const settings = {
  standard: {
    fontSize: '56px',
    lineHeight: '56px',
    textCase: 'uppercase',
  },
  small: {
    fontSize: '40px',
    lineHeight: '40px',
    textCase: 'uppercase',
  },
};

export const H4 = (props) => (
  <BaseText settings={settings} {...props}>
    <h4>{props.children}</h4>
  </BaseText>
);
