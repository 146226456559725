import React from 'react';
import BaseText from './BaseText';

const settings = {
  standard: {
    fontSize: '80px',
    lineHeight: '80px',
    textCase: 'uppercase',
  },
  small: {
    fontSize: '36px',
    lineHeight: '36px',
    textCase: 'uppercase',
  },
};

export const H2 = (props) => (
  <BaseText settings={settings} {...props}>
    <h2>{props.children}</h2>
  </BaseText>
);
