import React from 'react';
import BaseText from './BaseText';

const settings = {
  standard: {
    fontSize: '20px',
    lineHeight: '28px',
  },
  small: {
    fontSize: '16px',
    lineHeight: '24px',
  },
};

export const Blockquote = (props) => (
  <BaseText settings={settings} {...props}>
    <blockquote style={{ borderLeft: '2px solid', borderColor: 'inherit', margin: 0, padding: '8px 0 8px 24px' }}>
      {props.children}
    </blockquote>
  </BaseText>
);
