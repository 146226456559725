import { WEB_URL } from '../config';
import { PRODUCT_ANDROID_APP_STORE_URL, PRODUCT_IOS_APP_STORE_URL } from '../appConfig';

export const PATHS = {
  HOME: '/',
  SIGN_IN: `${WEB_URL}/login`,
  SIGN_UP: `${WEB_URL}/signup`,

  ABOUT: '/about',
  FAQ: '/faq',
  CONTACT: '/contact',
  ENTERPRISE: '/enterprise',

  LINKEDIN: 'https://www.linkedin.com/company/tornadoinvesting',
  TWITTER: 'https://twitter.com/TornadoInvest',
  INSTAGRAM: 'https://www.instagram.com/TornadoInvest/',

  FINRA: 'https://brokercheck.finra.org/firm/summary/269966',

  ENTERPRISE_CONTACT: '/enterprise-contact',
  BLOG: '/blog',
  MEDIUM_BLOG: 'https://blog.tornado.com',
  LEGAL: '/legal',

  pressMentionCNBC:
    'https://www.cnbc.com/video/2021/11/09/mobile-brokerage-tornado-focuses-on-financial-education-secures-investment-from-alex-rodriguez.html',
  pressMentionBloomberg:
    'https://www.bloomberg.com/news/articles/2021-07-21/a-rod-s-venture-firm-backs-online-brokerage-startup-tornado',
  pressMentionForbes:
    'https://www.forbes.com/sites/forbesfinancecouncil/2018/12/11/how-hedge-funds-invest-three-practical-steps-to-elevate-your-trading',
  pressMentionBusinessInsider: 'https://www.businessinsider.com/personal-finance/nvstr-review',
  pressMentionUsNews:
    'https://money.usnews.com/investing/investing-101/articles/2018-07-17/7-alternatives-to-investing-with-big-brokerages',
  pressMentionBenzinga: 'https://www.benzinga.com/money/nvstr-review/',
  pressMentionYahooFinance: 'https://finance.yahoo.com/news/financial-venture-studio-announces-newest-120000809.html',

  APP_STORE: PRODUCT_IOS_APP_STORE_URL,
  GOOGLE_PLAY: PRODUCT_ANDROID_APP_STORE_URL,

  CLIENT_RELATIONSHIP_SUMMARY: 'crs',
  FEE_SCHEDULE: 'fee-schedule',

  REFERRAL_PROGRAM_RULES: '/referral_rules',
  LEARN_AND_EARN_RULES: '/learn-and-earn-rules',
  FUNDING_BONUS_RULES: '/funding-bonus-rules',
  SURVEY_RULES: '/surveyrules',
  VIEWPOINT_CONTRIBUTOR_POSTING_GUIDELINES: '/viewpoint_guidelines',
  INFLUENCER_DISCLOSURE: '/influencer_disclosure',
  PRIVACY_POLICY: '/privacy_policy',
  MARGIN_DISCLOSURE: '/margin_disclosure',
  TERMS_OF_SERVICE: '/terms_of_service',
  FORGOT_PASSWORD: '/forgot_password',
  SIPC: 'https://www.sipc.org/for-investors/what-sipc-protects',

  REFERRALS: '/rewards',
  OPTIMIZER_RESEARCH: 'https://www.researchgate.net/publication/228318906_Minimum_Variance_Portfolio_Composition',

  ['606_REPORTING']: 'https://public.s3.com/rule606/apex/',
};
