import React from 'react';
import styled from 'styled-components';
import useButtonEvent from '@utils/itly/hooks/useButtonEvent';
import { FONT } from '../../appConfig';

export const WebappBaseButtonWrapper = styled.button`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  user-select: none;
  box-shadow: none;
  transition: background-color 300ms, color 300ms;

  border-radius: 6px;
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: auto;
  padding: 12px 24px;
  white-space: nowrap;
  line-height: 12px;
  text-align: center;

  * {
    text-align: center;
    font-family: ${({ theme }) => FONT.bold};
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
  }
`;

export const WebappBaseButton = (props) => {
  const { children } = props;
  const { onClick } = useButtonEvent(props);
  return (
    <WebappBaseButtonWrapper {...props} onClick={onClick}>
      {children}
    </WebappBaseButtonWrapper>
  );
};
