import React from 'react';
import BaseText from './BaseText';

const settings = {
  standard: {
    fontSize: '24px',
    lineHeight: '28px',
    textCase: 'uppercase',
  },
  small: {
    fontSize: '20px',
    lineHeight: '24px',
    textCase: 'uppercase',
  },
};

export const H6 = (props) => (
  <BaseText settings={settings} {...props}>
    <h6>{props.children}</h6>
  </BaseText>
);
