const getButtonText = (children) => {
  if (typeof children === 'string') {
    return children;
  }

  // element has multiple childs
  if (Array.isArray(children)) {
    return children
      .map(getButtonText)
      .filter((i) => !!i)
      .join(', ');
  }

  // element has child
  if (children?.props?.children) {
    return getButtonText(children?.props?.children);
  }
};

export default getButtonText;
