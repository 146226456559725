import React from 'react';
import BaseText from './BaseText';

const settings = {
  standard: {
    fontSize: '14px',
    lineHeight: '20px',
    textCase: 'uppercase',
  },
  small: {
    fontSize: '14px',
    lineHeight: '20px',
    textCase: 'uppercase',
  },
};

export const Eyebrow = ({
  isDarkMode,
  isLowContrast,
  isSmall,
  textCase,
  fontSize,
  children,
}) => (
  <BaseText
    isDarkMode={isDarkMode}
    isLowContrast={isLowContrast}
    isSmall={isSmall}
    textCase={textCase}
    fontSize={fontSize}
    settings={settings}
  >
    <p>{children}</p>
  </BaseText>
);
