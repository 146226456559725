import React from 'react';
import styled from 'styled-components';
import { Row, Column, Place, Modal, LoadingSpinner, Container, Body5, ModalWrapper } from '@lib/nvstr-common-ui.es';
import { colorPalette, themes } from '@lib/nvstr-utils.es';
import { Button1 } from '@components/Buttons';
import { signUpEmail } from '@utils/services';
import Link from 'next/link';
import { PATHS } from '@constants/paths';
import { LIGHT_COLOR_THEME_NAME } from '../../appConfig';
import { FlatButton } from '@components/Buttons/FlatButton';

const WrapperEmbed = styled.div`
  position: relative;
  width: 100%;
  z-index: 10;
`;

const SubmitButtonWrapper = styled.div`
  width: 100%;

  button {
    padding: 16px 24px;
  }
`;

const SpinnerWrap = styled.div`
  width: 80px;
  margin: 80px auto 0 auto;
`;

const EmbeddedSpinnerWrap = styled.div`
  svg {
    height: 30px;
    width: 30px;
  }

  div {
    margin: 0;
    height: 30px;
    width: 30px;
    vertical-align: unset;
  }
`;

const Title = styled.div`
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
`;

const InputLabel = styled.div`
  font-weight: 600;
  font-size: 12px;
`;

const InputContainer = styled.fieldset`
  position: relative;

  height: ${({ isEmbedded }) => (isEmbedded ? '53px' : 'auto')};
  padding: ${({ isEmbedded, isLoading }) => (isLoading ? '0' : isEmbedded ? '16px 12px' : '12px 10px 12px 0')};

  background: ${({ isMobile, isEmbedded }) => (isMobile || isEmbedded ? '#fff' : '')};
  border-radius: ${({ isEmbedded }) => (isEmbedded ? '6px' : '')};
`;

const InputField = styled.input`
  border-bottom: ${({ isEmbedded }) => (isEmbedded ? 'none' : '1px solid #222')};
  border-radius: ${({ isEmbedded }) => (isEmbedded ? 'none' : '0px')};

  padding: ${({ isEmbedded }) => (isEmbedded ? '0' : '16px 8px')};

  &:focus {
    outline: none;
  }
`;

const AlreadyHaveAccount = styled.div`
  padding: 20px 0 0 0;
  font-size: 14px;
`;

export const ValidationWrap = styled.div``;

export const ValidationText = styled.span`
  color: ${colorPalette.secondary.red};
`;

export const ModalContent = styled.div`
  padding: 48px;

  @media (max-width: 450px) {
    padding: 36px 16px;
  }
`;

export const GetStartedModalWrapper = styled.div`
  ${ModalWrapper} {
    background: ${({ theme }) => theme.lightTheme.componentNoOpacity} !important;
  }
  ${AlreadyHaveAccount} {
    * {
      color: ${({ theme }) => theme.lightTheme.text};
    }
    a {
      font-weight: bold;
    }
  }
`;

const EmbeddedForm = ({ isLoading, validationMessage, handleInputChange, handleSignUp, emailValue }) => {
  const [buttonEventProperties] = React.useState({ is_sign_up: true });
  const handleChange = (e) => handleInputChange(e.target.value);

  return (
    <WrapperEmbed>
      <InputContainer isEmbedded isLoading={isLoading}>
        {isLoading ? (
          <Row height="100%" width="100%" vAlign="center" hAlign="center">
            <EmbeddedSpinnerWrap>
              <LoadingSpinner />
            </EmbeddedSpinnerWrap>
          </Row>
        ) : (
          <>
            {validationMessage !== null && (
              <Place width="100%" zIndex={10} position="absolute" top="-28px">
                <div style={{ textAlign: 'center' }}>
                  <ValidationText>{validationMessage}</ValidationText>
                </div>
              </Place>
            )}
            <InputField
              type="email"
              placeholder="Email"
              value={emailValue}
              onChange={handleChange}
              autoComplete="email"
              isEmbedded={true}
            />
            <Place position="absolute" top="5px" right="6px">
              <Button1 height="39px" width="120px" onClick={handleSignUp} buttonEventProperties={buttonEventProperties}>
                GET STARTED
              </Button1>
            </Place>
          </>
        )}
      </InputContainer>
    </WrapperEmbed>
  );
};

const StandaloneForm = ({
  isLoading,
  validationMessage,
  handleInputChange,
  handleSignUp,
  handleCloseModal,
  emailValue,
  urlParams,
}) => {
  const colorTheme = themes[LIGHT_COLOR_THEME_NAME];
  const [buttonEventProperties] = React.useState({ is_sign_up: true });

  const handleChange = (e) => handleInputChange(e.target.value);

  return (
    <GetStartedModalWrapper>
      <Modal isAnimated onDismiss={handleCloseModal} modalProps={{ height: 'auto' }}>
        <ModalContent>
          <Title>Get Started in Minutes</Title>
          {isLoading && (
            <SpinnerWrap>
              <LoadingSpinner />
            </SpinnerWrap>
          )}
          {!isLoading && (
            <Column padding="0">
              {validationMessage !== null && (
                <Column padding="24px 0">
                  <ValidationWrap>
                    <ValidationText>{validationMessage}</ValidationText>
                  </ValidationWrap>
                </Column>
              )}

              <InputLabel>Email</InputLabel>
              <InputField
                type="email"
                placeholder={'Enter Your Email...'}
                value={emailValue}
                onChange={handleChange}
                autoComplete="email"
                autoFocus
              />
              <Container top={48}>
                <SubmitButtonWrapper>
                  <FlatButton
                    fullWidth
                    color={colorTheme.primaryCtaButton}
                    textColor={colorTheme.buttonText}
                    onClick={handleSignUp}
                    buttonEventProperties={buttonEventProperties}
                  >
                    CONTINUE
                  </FlatButton>
                </SubmitButtonWrapper>
              </Container>
            </Column>
          )}
          <AlreadyHaveAccount>
            <Body5>Already have an account? </Body5>
            <Link href={PATHS.SIGN_IN}>Sign In</Link>
          </AlreadyHaveAccount>
        </ModalContent>
      </Modal>
    </GetStartedModalWrapper>
  );
};

const SignUpForm = ({ setShowSignUpModal, urlParams = '', isEmbedded }) => {
  const [email, setEmail] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [validationMessage, setValidationMessage] = React.useState('');

  const userSignUpComplete = () => {
    window.location = `/app/signup?email=${encodeURIComponent(email)}`;
  };

  const closeModal = () => {
    setShowSignUpModal(false);
  };

  const userSignUpError = (errorMessage) => {
    setIsLoading(false);
    setValidationMessage(errorMessage);
  };

  const userSignUp = (e) => {
    const landerSlug = window.location.pathname;

    if (e) {
      e.preventDefault();
    }
    setIsLoading(true);

    if (email.trim() !== '') {
      signUpEmail({
        email,
        userSignUpComplete,
        userSignUpError,
        landerSlug,
      });
    } else {
      setIsLoading(false);
      setValidationMessage('Please enter your email.');
    }
  };

  if (isEmbedded) {
    return (
      <EmbeddedForm
        isLoading={isLoading}
        validationMessage={validationMessage}
        emailValue={email}
        handleInputChange={setEmail}
        handleSignUp={userSignUp}
      />
    );
  }

  return (
    <StandaloneForm
      isLoading={isLoading}
      validationMessage={validationMessage}
      emailValue={email}
      handleInputChange={setEmail}
      handleSignUp={userSignUp}
      handleCloseModal={closeModal}
      urlParams={urlParams}
    />
  );
};

export default SignUpForm;
