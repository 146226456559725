import React from 'react';
import { withTheme } from 'styled-components';
import { Text } from '@lib/nvstr-common-ui.es';
import { DARK_COLOR_THEME_NAME } from '../../appConfig';

const BaseText = (props) => {
  const {
    isDarkMode,
    isLowContrast,
    isSmall,
    isLarge,
    settings,
    children,
    theme,
    textCase: _overrideTextCase,
    fontSize: _overrideFontSize,
  } = props;

  const { isMobile, themeColors, themes } = theme;
  const lightTheme = themeColors;
  const darkTheme = themes[DARK_COLOR_THEME_NAME];

  const fontSettings = (isSmall || isMobile) && !isLarge ? settings.small : settings.standard;
  const { fontSize, lineHeight, textCase, fontWeight } = fontSettings;
  const color = isDarkMode ? darkTheme.text : lightTheme.text;
  const opacity = isLowContrast ? 0.8 : 1;
  return (
    <Text
      {...props}
      color={color}
      textTransform={_overrideTextCase || textCase || 'none'}
      fontWeight={fontWeight || 'inherit'}
      fontSize={_overrideFontSize || fontSize}
      lineHeight={lineHeight}
      opacity={opacity}
    >
      {children}
    </Text>
  );
};

export default withTheme(BaseText);
