import React from 'react';
import { withTheme } from 'styled-components';
import Line from '@components/Primitives/Line';
import { DARK_COLOR_THEME_NAME } from '../../appConfig';
import { colorPalette } from '@lib/nvstr-utils.es';

const HeaderLine = ({ isDarkMode, forceDark, isLowContrast, height, theme }) => {
  const { isMobile, themeColors, themes } = theme;
  const lightTheme = themeColors;
  const darkTheme = themes[DARK_COLOR_THEME_NAME];

  const color = forceDark ? colorPalette.primary.charcoal : isDarkMode ? darkTheme.text : lightTheme.text;
  const opacity = isLowContrast ? 0.2 : 1;
  return <Line color={color} opacity={opacity} height={height} />;
};

export default withTheme(HeaderLine);
