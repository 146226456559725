import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.span`
  * {
    font-family: Bolton-Regular, arial !important;
    font-weight: 100 !important;
  }

  font-family: Bolton-Regular, arial !important;
  font-weight: 100 !important;
`;

export const Thin = ({ children }) => <Wrapper className={'thin'}>{children}</Wrapper>;
