import application from './events/application';
import faq from './events/faq';
import splash from './events/splash';
import aboutUs from './events/aboutUs';
import enterprise from './events/enterprise';

export const TrackingEvents = {
  application,
  splash,
  aboutUs,
  enterprise,
  faq,
};
