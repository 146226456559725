import React from 'react';
import BaseText from './BaseText';

const settings = {
  standard: {
    fontSize: '18px',
    lineHeight: '24px',
  },
  small: {
    fontSize: '14px',
    lineHeight: '20px',
  },
};

export const Body1 = (props) => (
  <BaseText settings={settings} {...props}>
    <p>{props.children}</p>
  </BaseText>
);
