import React from 'react';
import { Body4, Bold } from './';

export const TextDefinition = ({ phrase, definition }) => {
  return (
    <Body4>
      {phrase && (
        <>
          <Bold>{phrase}</Bold>{' '}
        </>
      )}

      {definition}
    </Body4>
  );
};
