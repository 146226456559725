import { useCallback, useContext } from 'react';
import { useRouter } from 'next/router';
import { TrackIteratively } from '../index';
import getButtonText from '../../getButtonText';
import { analyticsPageNames } from '@utils/itly/analyticTypes';
import { ElementPositionContext } from '../../../context';
import { enums } from '@lib/nvstr-utils.es';
const { platform, node_location } = enums;

const useButtonEvent = (props) => {
  const router = useRouter();
  const { elementPosition } = useContext(ElementPositionContext);

  const onClick = useCallback(() => {
    const btnPosition = elementPosition || node_location.body;

    const properties = {
      button_type: props.buttonType,
      context: analyticsPageNames[router.route] || 'Public Website',
      description: getButtonText(props.children),
      position: btnPosition,
      platform: platform.web,
      url: router.pathname,
      url_query: router.query,
      ...(props.buttonEventProperties || {}),
    };

    TrackIteratively.generic.BUTTON_CLICKED.send(properties);
    props.onClick && props.onClick();
  }, [props.onClick, props.buttonType, router]);

  return {
    onClick,
  };
};

export default useButtonEvent;
