import React from 'react';
import styled, { withTheme } from 'styled-components';
import Link from 'next/link';
import LogoFull from '@components/Images/LogoFull';
import Hamburger from '@components/Images/Hamburger';
import { Row, Column, Place } from '@lib/nvstr-common-ui.es';
import { Button2 } from '@components/Buttons';
import { Section } from '@components/Layout';
import { Eyebrow } from '@components/Text';
import { WEB_URL } from '../../config';
import { TrackingEvents } from '../../tracking';
import { PATHS } from '@constants/paths';
import { ElementPositionContext } from '../../context';
import { enums } from '@lib/nvstr-utils.es';
import { IS_WHITE_LABELED } from '../../appConfig';
import { MobileMenu } from '../../container/application/MobileMenu';
const { node_location } = enums;

const NavItem = styled(Column)`
  max-width: 124px;
  padding: 10px 16px;
  text-align: center;
  cursor: pointer;
`;

const A = styled.a`
  display: flex;
  text-decoration: none;
`;

const HeaderMobile = ({ isDarkMode = false, theme, urlParams, onShowSignUpModal, forceBgColor }) => {
  const [showHamburgerNav, setShowHamburgerNav] = React.useState(false);
  const onHideMenu = () => {
    setShowHamburgerNav(false);
  };

  return (
    <Row
      position="relative"
      vAlign="center"
      height="60px"
      padding="20px 0 20px 0"
      zIndex="4"
      background={forceBgColor ? forceBgColor : isDarkMode ? theme.primary.charcoal : theme.secondary.white}
    >
      {showHamburgerNav ? (
        <Place position="absolute" top="60px" left="0" width="100vw" zIndex="5">
          <MobileMenu theme={theme} isDarkMode={isDarkMode} hideMenu={onHideMenu} />
        </Place>
      ) : null}
      <Column width="100%" hAlign="center" vAlign="center">
        <Row position="relative" width="100%" style={{ justifyContent: 'space-between' }}>
          <Column width="60px" vAlign="center">
            <button
              onClick={() => {
                setShowHamburgerNav(!showHamburgerNav);
              }}
            >
              <Column padding="4px 0 0 16px" height={'50px'} vAlign="center">
                <Hamburger width={27} isDarkMode={isDarkMode} id="nav-ham" />
              </Column>
            </button>
          </Column>
          <Column width="50px" hAlign="center" vAlign="center" padding="4px 0 0 0">
            <A href={PATHS.HOME + urlParams}>
              <LogoFull isDarkMode={isDarkMode} />
            </A>
          </Column>
          <Column width="112px" hAlign="center" vAlign="center">
            <Place>
              <Button2
                height={'36px'}
                width={'80px'}
                borderRadius="8px"
                isDarkMode={isDarkMode}
                onClick={onShowSignUpModal}
              >
                Sign Up
              </Button2>
            </Place>
          </Column>
        </Row>
      </Column>
    </Row>
  );
};

const HeaderFull = ({ isDarkMode = false, urlParams, onShowSignUpModal, forceBgColor }) => {
  const handleGetStartedClick = () => {
    TrackingEvents.splash.clickGetStartedButton.send({
      Context: 'Header Nav',
    });
  };

  return (
    <Row
      position="relative"
      hAlign="center"
      vAlign="center"
      height="84px"
      padding="0px 40px 0 40px"
      zIndex="4"
      background={forceBgColor || 'inherit'}
    >
      <Column width="210px" vAlign="center">
        <Link href="/" className={'no-text-decoration'}>
          <LogoFull isDarkMode={isDarkMode} />
        </Link>
      </Column>
      <Column width="calc(100% - 500px)" hAlign="center" vAlign="center">
        {IS_WHITE_LABELED ? (
          <></>
        ) : (
          <Row width="450px" hAlign="center" position="relative" left="30px">
            <Link href="/about" className={'no-text-decoration'}>
              <NavItem >
                <Eyebrow isDarkMode={isDarkMode}>ABOUT</Eyebrow>
              </NavItem>
            </Link>
            <Link href="/ai" className={'no-text-decoration'}>
              <NavItem >
                <Eyebrow isDarkMode={isDarkMode}>AI</Eyebrow>
              </NavItem>
            </Link>
            <Link href="/enterprise" className={'no-text-decoration'}>
              <NavItem >
                <Eyebrow isDarkMode={isDarkMode}>Enterprise</Eyebrow>
              </NavItem>
            </Link>
            <Link href="/blog" className={'no-text-decoration'}>
              <NavItem>
                <Eyebrow isDarkMode={isDarkMode}>Blog</Eyebrow>
              </NavItem>
            </Link>
          </Row>
        )}
      </Column>
      <Column width="290px" vAlign="center">
        <Row>
          <A href={`${WEB_URL}/login${urlParams}`}>
            <NavItem vAlign="center">
              <Eyebrow isDarkMode={isDarkMode}>LOG IN</Eyebrow>
            </NavItem>
          </A>
          <NavItem vAlign="center" onClick={onShowSignUpModal}>
            <Button2 isDarkMode={isDarkMode} onClick={handleGetStartedClick}>
              GET STARTED
            </Button2>
          </NavItem>
        </Row>
      </Column>
    </Row>
  );
};

const HeaderMain = (props) => {
  const { theme, useTransparentHeader, isDarkMode, forceBgColor } = props;
  const { isMobile } = theme;

  if (isMobile) {
    return <HeaderMobile {...props} />;
  }

  return (
    <ElementPositionContext.Provider value={{ elementPosition: node_location.header }}>
      <Section
        background={
          forceBgColor ? forceBgColor : useTransparentHeader || !isDarkMode ? 'transparent' : theme.primary.charcoal
        }
      >
        <HeaderFull {...props} />
      </Section>
    </ElementPositionContext.Provider>
  );
};

export default withTheme(HeaderMain);
