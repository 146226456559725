import { logAmplitudeEvent } from '@utils/amplitude';

const viewAboutUs = {
  send: (properties) => {
    const event = 'View About Us';
    logAmplitudeEvent(event, properties);
  },
};

const clickFINRAButton = {
  send: (properties) => {
    const event = 'Clicked Finra Link';
    logAmplitudeEvent(event, properties);
  },
};

const trackingEvents = {
  viewAboutUs,
  clickFINRAButton,
};

export default trackingEvents;
