import styled from 'styled-components';
import { Column } from '@lib/nvstr-common-ui.es';
import useButtonEvent from '@utils/itly/hooks/useButtonEvent';
import { enums } from '@lib/nvstr-utils.es';
const { button_types } = enums;

const AppBaseButtonWrapper = styled(Column).attrs(() => ({
  hAlign: 'center',
  vAlign: 'center',
}))`
  border-radius: ${(props) => props.borderRadius || '4px'};
  cursor: pointer;
  user-select: none;
  width: ${(props) => props.width || '200px'};
  height: ${(props) => props.height || '64px'};
  background: ${(props) => props.background || ''};
  border: 2px solid ${(props) => props.borderColor || props.background};
  transition: background-color 300ms, color 300ms;

  * {
    fill: ${(props) => props.color};
  }

  div,
  span {
    color: ${(props) => props.color};
  }
  &:hover {
    background: ${(props) => props.hoverBackground || props.background};
    border: 2px solid ${(props) => props.borderHoverColor || props.background};
    opacity: ${(props) => (props.opacityHoverEffect ? 0.8 : 1)};

    * {
      fill: ${(props) => props.hoverColor || props.color};
    }
    div,
    span {
      color: ${(props) => props.hoverColor || props.color};
    }
  }
`;

export const BaseButton = (props) => {
  const { onClick } = useButtonEvent(props);
  return <AppBaseButtonWrapper {...props} onClick={onClick} />;
};

BaseButton.defaultProps = {
  buttonType: button_types.button,
};

export default BaseButton;
