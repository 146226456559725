import styled from 'styled-components';

const Line = styled('div')`
  border-radius: ${(props) => props.borderRadius || '0px'};
  background: ${(props) => props.color || props.background || '#404040'};

  height: ${(props) => props.height || '1px'};
  width: ${(props) => props.width || '100%'};
  opacity: ${(props) => props.opacity || '1'};

  user-select: none;
`;

export default Line;
