import React from 'react';
import styled, { withTheme } from 'styled-components';
import Link from 'next/link';
import LogoFull from '@components/Images/LogoFull';
import { Row, Column, Place } from '@lib/nvstr-common-ui.es';
import { ElementPositionContext } from '../../context';
import { enums } from '@lib/nvstr-utils.es';
import Hamburger from '@components/Images/Hamburger';
import { MobileMenu } from '../../container/application/MobileMenu';
import { useWebappNavigate } from '@hooks/useWebappNavigate';
const { node_location } = enums;

const LogoLinkWrapper = styled.div`
  cursor: pointer;
  padding: 1px 16px 0 16px;
  line-height: 1;
  text-decoration: none;

  * {
    line-height: 1;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  height: 60px;
  padding: 0 40px;
  border-bottom: 1px solid ${({ theme }) => theme.themeColors.lowContrastBorder};

  background: ${({ theme }) => theme.themeColors.componentNoOpacity};
`;

const Mobile = ({ isDarkMode }) => {
  const navigate = useWebappNavigate();

  const [showHamburgerNav, setShowHamburgerNav] = React.useState(false);
  const onHideMenu = () => {
    setShowHamburgerNav(false);
  };
  const handleClick = () => {
    navigate('/');
  };

  return (
    <HeaderWrapper>
      {showHamburgerNav ? (
        <Place position="absolute" top="60px" left="0" width="100vw" zIndex="5">
          <MobileMenu hideMenu={onHideMenu} />
        </Place>
      ) : null}

      <Place position="absolute" top="4px" left="40px" width="100vw" zIndex="5">
        <button
          onClick={() => {
            setShowHamburgerNav(!showHamburgerNav);
          }}
        >
          <Column padding="4px 0 0 16px" height={'50px'} vAlign="center">
            <Hamburger width={27} isDarkMode={isDarkMode} id="nav-ham" />
          </Column>
        </button>
      </Place>

      <LogoLinkWrapper onClick={handleClick}>
        <LogoFull isDarkMode={isDarkMode} />
      </LogoLinkWrapper>
      <div></div>
    </HeaderWrapper>
  );
};

const Desktop = ({ isDarkMode }) => {
  const navigate = useWebappNavigate();

  const handleClick = () => {
    navigate('/');
  };
  return (
    <HeaderWrapper>
      <LogoLinkWrapper onClick={handleClick}>
        <LogoFull isDarkMode={isDarkMode} />
      </LogoLinkWrapper>
      <div></div>
    </HeaderWrapper>
  );
};

const AppHeader = (props) => {
  const { theme } = props;
  const { isMobile, isDarkTheme } = theme;

  if (isMobile) {
    return <Mobile {...props} isDarkMode={isDarkTheme} />;
  }

  return (
    <ElementPositionContext.Provider value={{ elementPosition: node_location.header }}>
      <Desktop {...props} isDarkMode={isDarkTheme} />
    </ElementPositionContext.Provider>
  );
};

export default withTheme(AppHeader);
