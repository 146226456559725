import React from 'react';
import { useRouter } from 'next/router';
import { createQueryString } from '@lib/nvstr-utils.es';

export const useSetSearchParams = () => {
  const router = useRouter();
  const { query, pathname } = router;

  const extendedSetSearchParams = React.useCallback(
    (searchParams, shouldClearCurrentParams) => {
      const currentSearchParams = shouldClearCurrentParams ? {} : query;
      const nextSearchParams = {
        ...currentSearchParams,
        ...searchParams,
      };
      const url = pathname + createQueryString(nextSearchParams);
      return router.replace(url);
    },
    [router]
  );
  return extendedSetSearchParams;
};

export const useClearSearchParams = () => {
  const { query } = useRouter();
  const setSearchParams = useSetSearchParams();

  const clearSomeSearchParams = React.useCallback(
    (params) => {
      const cloneQuery = { ...query };
      Object.keys(params).forEach((k) => delete cloneQuery[k]);
      const nextQuery = {
        ...cloneQuery,
      };
      return setSearchParams(nextQuery, true);
    },
    [query, setSearchParams]
  );

  return clearSomeSearchParams;
};
