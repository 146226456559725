import React from 'react';
import BaseText from './BaseText';

const settings = {
  standard: {
    fontSize: '100px',
    lineHeight: '100px',
    textCase: 'uppercase',
  },
  small: {
    fontSize: '40px',
    lineHeight: '40px',
    textCase: 'uppercase',
  },
};

export const H1 = (props) => (
  <BaseText settings={settings} {...props}>
    <h1>{props.children}</h1>
  </BaseText>
);
